import pick from 'just-pick'
import type {
  Fee,
  OrderShipping,
  Address,
  OrderItem,
  OrderVoucher,
} from '~/types/api'
import getWarrantyPrice from '~/utils/product/getWarrantyPrice'

function serialize(value: any) {
  return JSON.stringify(value, (_key, value) => {
    if (value instanceof Map) {
      return {
        type: 'Map',
        value: Array.from(value.entries()),
      }
    }
    return value
  })
}

function deserialize(value: string) {
  const data = JSON.parse(value, (_key, value) => {
    if (value && value.type === 'Map') {
      return new Map(value.value)
    }
    return value
  })

  // TODO: Remove this after ±1 week of deployment
  //#region
  if (data.shipping?.price === null) {
    data.shipping = null
  }

  if (data.shipping !== null && data.shipping.insurancePrice === undefined) {
    data.shipping.insurancePrice = 0
  }

  if (data.fee?.amount === null) {
    data.fee = null
  }

  data.items = undefined
  //#endregion

  return data
}

export const useOrder = defineStore(
  'order',
  () => {
    const version = ref(1)

    function $reset() {
      orderItems.value = new Map()
      clearDelivery()
      clearShipping()
      clearVoucher()
      clearFee()
      clearLocation()
    }

    function toString() {
      return serialize({
        version: unref(version),
        orderItems: unref(orderItems),
        delivery: unref(delivery),
        shipping: unref(shipping),
        voucher: unref(voucher),
        fee: unref(fee),
        location: unref(location),
      })
    }

    //#region Items
    const orderItems = ref<Map<number, OrderItem>>(new Map())

    const items = computed(() => {
      return [...orderItems.value.values()].map((item) => ({
        ...item,
        // TODO: Figure out how to auto-import `getWarrantyPrice` util function
        warrantyPrice: getWarrantyPrice(
          item.product.price,
          item.warranty.percent,
        ),
      }))
    })

    const itemsEmpty = computed(() => items.value.length === 0)

    function addItem(item: OrderItem) {
      orderItems.value.set(item.product.id, item)
    }

    function updateItem(item: OrderItem) {
      orderItems.value.set(item.product.id, item)
    }

    function removeItem(productId: number) {
      orderItems.value.delete(productId)
    }

    function clearItems() {
      orderItems.value.clear()
    }
    //#endregion

    //#region Delivery Address
    // TODO: Refactor to use `deliveryAddress` instead of `delivery`
    const delivery = ref<Address | null>(null)

    function setDelivery(address: Address) {
      delivery.value = address
    }

    function clearDelivery() {
      delivery.value = null
    }
    //#endregion

    //#region Shipping Method
    // TODO: Refactor to use `shippingMethod` instead of `shipping`
    const shipping = ref<OrderShipping | null>(null)

    // TODO: Add type for `method`
    function setShipping(method: OrderShipping) {
      shipping.value = method
    }

    function clearShipping() {
      shipping.value = null
    }
    //#endregion

    //#region Voucher
    const voucher = ref<OrderVoucher | null>(null)

    function setVoucher(v: OrderVoucher) {
      voucher.value = v
    }

    function clearVoucher() {
      voucher.value = null
    }
    //#endregion

    //#region Fee
    // TODO: Refactor to use array of `OrderFee` instead of single `fee`
    const fee = ref<Fee | null>(null)

    const setFee = (f: Fee) => {
      fee.value = f
    }

    const clearFee = () => {
      fee.value = null
    }
    //#endregion

    //#region Totals
    const subTotal = computed(() =>
      items.value.reduce(
        (total, item) =>
          total + (item.product.price + item.warrantyPrice) * item.quantity,
        0,
      ),
    )

    const total = computed(
      () =>
        subTotal.value -
        (voucher.value?.discount || 0) +
        (shipping.value?.price || 0) +
        (shipping.value?.insurancePrice || 0),
    )

    const grandTotal = computed(() => total.value + (fee.value?.amount || 0))
    //#endregion

    //#region Location
    // TODO: Refactor to separate composable
    const location = ref<{
      provinceId: number
      cityId: number
      districtId: number
      subdistrictId: number
    }>({
      provinceId: 0,
      cityId: 0,
      districtId: 0,
      subdistrictId: 0,
    })

    function setLocation(l: {
      provinceId: number
      cityId: number
      districtId: number
      subdistrictId: number
    }) {
      location.value = l
    }

    function clearLocation() {
      location.value = {
        provinceId: 0,
        cityId: 0,
        districtId: 0,
        subdistrictId: 0,
      }
    }
    //#endregion

    return {
      version,
      $reset,
      toString,

      orderItems,
      items,
      itemsEmpty,
      addItem,
      updateItem,
      removeItem,
      clearItems,

      delivery,
      setDelivery,
      clearDelivery,

      shipping,
      setShipping,
      clearShipping,

      voucher,
      setVoucher,
      clearVoucher,

      fee,
      setFee,
      clearFee,

      subTotal,
      total,
      grandTotal,

      location,
      setLocation,
      clearLocation,
    }
  },
  {
    persist: {
      storage: piniaPluginPersistedstate.localStorage(),
      afterHydrate(context) {
        if (context.store.version !== 1) {
          context.store.$reset()
          context.store.$persist()
        }
      },
      serializer: {
        serialize,
        deserialize,
      },
    },
  },
)
