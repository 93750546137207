import type { EventHint, Scope, SeverityLevel } from '@sentry/vue'

export default function useSentry() {
  const { $sentry } = useNuxtApp()

  function captureException(error: Error) {
    return $sentry?.captureException(error)
  }

  function captureMessage(
    message: string,
    level?: SeverityLevel,
    hint?: EventHint,
    currentScope?: Scope,
  ) {
    return $sentry?.captureMessage(message, level, hint, currentScope)
  }

  return {
    captureException,
    captureMessage,
  }
}
