import { default as contact_45us5WdhgbkZvcMeta } from "/usr/src/app/pages/(contact-form)/contact-us.vue?macro=true";
import { default as corporate_45inquirywvhiLJu49EMeta } from "/usr/src/app/pages/(contact-form)/corporate-inquiry.vue?macro=true";
import { default as flash_45saleZV1mt9lVOwMeta } from "/usr/src/app/pages/(marketing)/flash-sale.vue?macro=true";
import { default as _91slug_93eSbBQ9nue6Meta } from "/usr/src/app/pages/[type]/[slug].vue?macro=true";
import { default as about_45usCsa1Q1uHpWMeta } from "/usr/src/app/pages/about-us.vue?macro=true";
import { default as addresses1USOpCZ7cbMeta } from "/usr/src/app/pages/account/addresses.vue?macro=true";
import { default as notificationsCp6yLRkO4JMeta } from "/usr/src/app/pages/account/notifications.vue?macro=true";
import { default as ordersVRYxFTVlPnMeta } from "/usr/src/app/pages/account/orders.vue?macro=true";
import { default as payment_45methodsQc2MB8ajkJMeta } from "/usr/src/app/pages/account/payment-methods.vue?macro=true";
import { default as profileaKN25GPWXpMeta } from "/usr/src/app/pages/account/profile.vue?macro=true";
import { default as securityOhxuTv8x0fMeta } from "/usr/src/app/pages/account/security.vue?macro=true";
import { default as accountdWl6ticfZzMeta } from "/usr/src/app/pages/account.vue?macro=true";
import { default as _91socialProvider_93rWKfKuCKLkMeta } from "/usr/src/app/pages/callback/[socialProvider].vue?macro=true";
import { default as gopaytOoQzYtXjLMeta } from "/usr/src/app/pages/callback/gopay.vue?macro=true";
import { default as paymentDcTrBRj9bgMeta } from "/usr/src/app/pages/callback/payment.vue?macro=true";
import { default as account6uzEko4cZ0Meta } from "/usr/src/app/pages/checkout/account.vue?macro=true";
import { default as cartOniHQxzHIkMeta } from "/usr/src/app/pages/checkout/cart.vue?macro=true";
import { default as confirmationEo0CRkLUGFMeta } from "/usr/src/app/pages/checkout/confirmation.vue?macro=true";
import { default as failure5UgGYcSu3NMeta } from "/usr/src/app/pages/checkout/failure.vue?macro=true";
import { default as guestirFMj7aDhjMeta } from "/usr/src/app/pages/checkout/guest.vue?macro=true";
import { default as paymentKzZ4hFQrfEMeta } from "/usr/src/app/pages/checkout/payment.vue?macro=true";
import { default as shipping6wt142kqyHMeta } from "/usr/src/app/pages/checkout/shipping.vue?macro=true";
import { default as success7Kujq89Y8uMeta } from "/usr/src/app/pages/checkout/success.vue?macro=true";
import { default as checkoutShZmPbbKc4Meta } from "/usr/src/app/pages/checkout.vue?macro=true";
import { default as _91slug_93HAzPQI1b4NMeta } from "/usr/src/app/pages/embed/[type]/[slug].vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as loginRGcvZC480vMeta } from "/usr/src/app/pages/login.vue?macro=true";
import { default as _91slug_93BiYOJvW0TAMeta } from "/usr/src/app/pages/policy/[slug].vue?macro=true";
import { default as _91slug_93rJ4KGHwFnjMeta } from "/usr/src/app/pages/product/[slug].vue?macro=true";
import { default as gopartnerpSSw91hXOuMeta } from "/usr/src/app/pages/promo/gopartner.vue?macro=true";
import { default as gopay_45laterl6IKvbT2t7Meta } from "/usr/src/app/pages/promo/gopay-later.vue?macro=true";
import { default as grab_45benefitsuBYVN2cwwGMeta } from "/usr/src/app/pages/promo/grab-benefits.vue?macro=true";
import { default as kredivoYfLvwup4ftMeta } from "/usr/src/app/pages/promo/kredivo.vue?macro=true";
import { default as register6aSL4CEIZgMeta } from "/usr/src/app/pages/register.vue?macro=true";
import { default as confirmfMckeCtlYaMeta } from "/usr/src/app/pages/resale/buyback/confirm.vue?macro=true";
import { default as indexc28DzaepbLMeta } from "/usr/src/app/pages/resale/buyback/index.vue?macro=true";
import { default as thank_45youPmSE71MAgJMeta } from "/usr/src/app/pages/resale/thank-you.vue?macro=true";
import { default as confirmYRXiBNYoKeMeta } from "/usr/src/app/pages/resale/trade-in/confirm.vue?macro=true";
import { default as indexqqqXFHg9E5Meta } from "/usr/src/app/pages/resale/trade-in/index.vue?macro=true";
import { default as _91orderNumber_93sJrKzifOf5Meta } from "/usr/src/app/pages/warranty-card/[orderNumber].vue?macro=true";
import { default as component_45stubuYxk0V0rW5Meta } from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubuYxk0V0rW5 } from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "contact-us___en",
    path: "/en/contact-us",
    component: () => import("/usr/src/app/pages/(contact-form)/contact-us.vue")
  },
  {
    name: "contact-us___id",
    path: "/contact-us",
    component: () => import("/usr/src/app/pages/(contact-form)/contact-us.vue")
  },
  {
    name: "corporate-inquiry___en",
    path: "/en/corporate-inquiry",
    component: () => import("/usr/src/app/pages/(contact-form)/corporate-inquiry.vue")
  },
  {
    name: "corporate-inquiry___id",
    path: "/corporate-inquiry",
    component: () => import("/usr/src/app/pages/(contact-form)/corporate-inquiry.vue")
  },
  {
    name: "flash-sale___en",
    path: "/en/flash-sale",
    component: () => import("/usr/src/app/pages/(marketing)/flash-sale.vue")
  },
  {
    name: "flash-sale___id",
    path: "/flash-sale",
    component: () => import("/usr/src/app/pages/(marketing)/flash-sale.vue")
  },
  {
    name: "type-slug___en",
    path: "/en/:type()/:slug()",
    meta: _91slug_93eSbBQ9nue6Meta || {},
    component: () => import("/usr/src/app/pages/[type]/[slug].vue")
  },
  {
    name: "type-slug___id",
    path: "/:type()/:slug()",
    meta: _91slug_93eSbBQ9nue6Meta || {},
    component: () => import("/usr/src/app/pages/[type]/[slug].vue")
  },
  {
    name: "about-us___en",
    path: "/en/about-us",
    component: () => import("/usr/src/app/pages/about-us.vue")
  },
  {
    name: "about-us___id",
    path: "/about-us",
    component: () => import("/usr/src/app/pages/about-us.vue")
  },
  {
    name: "account___en",
    path: "/en/account",
    meta: accountdWl6ticfZzMeta || {},
    component: () => import("/usr/src/app/pages/account.vue"),
    children: [
  {
    name: "account-addresses___en",
    path: "addresses",
    component: () => import("/usr/src/app/pages/account/addresses.vue")
  },
  {
    name: "account-notifications___en",
    path: "notifications",
    component: () => import("/usr/src/app/pages/account/notifications.vue")
  },
  {
    name: "account-orders___en",
    path: "orders",
    component: () => import("/usr/src/app/pages/account/orders.vue")
  },
  {
    name: "account-payment-methods___en",
    path: "payment-methods",
    component: () => import("/usr/src/app/pages/account/payment-methods.vue")
  },
  {
    name: "account-profile___en",
    path: "profile",
    component: () => import("/usr/src/app/pages/account/profile.vue")
  },
  {
    name: "account-security___en",
    path: "security",
    component: () => import("/usr/src/app/pages/account/security.vue")
  }
]
  },
  {
    name: "account___id",
    path: "/account",
    meta: accountdWl6ticfZzMeta || {},
    component: () => import("/usr/src/app/pages/account.vue"),
    children: [
  {
    name: "account-addresses___id",
    path: "addresses",
    component: () => import("/usr/src/app/pages/account/addresses.vue")
  },
  {
    name: "account-notifications___id",
    path: "notifications",
    component: () => import("/usr/src/app/pages/account/notifications.vue")
  },
  {
    name: "account-orders___id",
    path: "orders",
    component: () => import("/usr/src/app/pages/account/orders.vue")
  },
  {
    name: "account-payment-methods___id",
    path: "payment-methods",
    component: () => import("/usr/src/app/pages/account/payment-methods.vue")
  },
  {
    name: "account-profile___id",
    path: "profile",
    component: () => import("/usr/src/app/pages/account/profile.vue")
  },
  {
    name: "account-security___id",
    path: "security",
    component: () => import("/usr/src/app/pages/account/security.vue")
  }
]
  },
  {
    name: "callback-socialProvider___en",
    path: "/en/callback/:socialProvider()",
    meta: _91socialProvider_93rWKfKuCKLkMeta || {},
    component: () => import("/usr/src/app/pages/callback/[socialProvider].vue")
  },
  {
    name: "callback-socialProvider___id",
    path: "/callback/:socialProvider()",
    meta: _91socialProvider_93rWKfKuCKLkMeta || {},
    component: () => import("/usr/src/app/pages/callback/[socialProvider].vue")
  },
  {
    name: "callback-gopay___en",
    path: "/en/callback/gopay",
    meta: gopaytOoQzYtXjLMeta || {},
    component: () => import("/usr/src/app/pages/callback/gopay.vue")
  },
  {
    name: "callback-gopay___id",
    path: "/callback/gopay",
    meta: gopaytOoQzYtXjLMeta || {},
    component: () => import("/usr/src/app/pages/callback/gopay.vue")
  },
  {
    name: "callback-payment___en",
    path: "/en/callback/payment",
    meta: paymentDcTrBRj9bgMeta || {},
    component: () => import("/usr/src/app/pages/callback/payment.vue")
  },
  {
    name: "callback-payment___id",
    path: "/callback/payment",
    meta: paymentDcTrBRj9bgMeta || {},
    component: () => import("/usr/src/app/pages/callback/payment.vue")
  },
  {
    name: "checkout___en",
    path: "/en/checkout",
    meta: checkoutShZmPbbKc4Meta || {},
    component: () => import("/usr/src/app/pages/checkout.vue"),
    children: [
  {
    name: "checkout-account___en",
    path: "account",
    meta: account6uzEko4cZ0Meta || {},
    component: () => import("/usr/src/app/pages/checkout/account.vue")
  },
  {
    name: "checkout-cart___en",
    path: "cart",
    component: () => import("/usr/src/app/pages/checkout/cart.vue")
  },
  {
    name: "checkout-confirmation___en",
    path: "confirmation",
    component: () => import("/usr/src/app/pages/checkout/confirmation.vue")
  },
  {
    name: "checkout-failure___en",
    path: "failure",
    component: () => import("/usr/src/app/pages/checkout/failure.vue")
  },
  {
    name: "checkout-guest___en",
    path: "guest",
    meta: guestirFMj7aDhjMeta || {},
    component: () => import("/usr/src/app/pages/checkout/guest.vue")
  },
  {
    name: "checkout-payment___en",
    path: "payment",
    meta: paymentKzZ4hFQrfEMeta || {},
    component: () => import("/usr/src/app/pages/checkout/payment.vue")
  },
  {
    name: "checkout-shipping___en",
    path: "shipping",
    meta: shipping6wt142kqyHMeta || {},
    component: () => import("/usr/src/app/pages/checkout/shipping.vue")
  },
  {
    name: "checkout-success___en",
    path: "success",
    component: () => import("/usr/src/app/pages/checkout/success.vue")
  }
]
  },
  {
    name: "checkout___id",
    path: "/checkout",
    meta: checkoutShZmPbbKc4Meta || {},
    component: () => import("/usr/src/app/pages/checkout.vue"),
    children: [
  {
    name: "checkout-account___id",
    path: "account",
    meta: account6uzEko4cZ0Meta || {},
    component: () => import("/usr/src/app/pages/checkout/account.vue")
  },
  {
    name: "checkout-cart___id",
    path: "cart",
    component: () => import("/usr/src/app/pages/checkout/cart.vue")
  },
  {
    name: "checkout-confirmation___id",
    path: "confirmation",
    component: () => import("/usr/src/app/pages/checkout/confirmation.vue")
  },
  {
    name: "checkout-failure___id",
    path: "failure",
    component: () => import("/usr/src/app/pages/checkout/failure.vue")
  },
  {
    name: "checkout-guest___id",
    path: "guest",
    meta: guestirFMj7aDhjMeta || {},
    component: () => import("/usr/src/app/pages/checkout/guest.vue")
  },
  {
    name: "checkout-payment___id",
    path: "payment",
    meta: paymentKzZ4hFQrfEMeta || {},
    component: () => import("/usr/src/app/pages/checkout/payment.vue")
  },
  {
    name: "checkout-shipping___id",
    path: "shipping",
    meta: shipping6wt142kqyHMeta || {},
    component: () => import("/usr/src/app/pages/checkout/shipping.vue")
  },
  {
    name: "checkout-success___id",
    path: "success",
    component: () => import("/usr/src/app/pages/checkout/success.vue")
  }
]
  },
  {
    name: "embed-type-slug___en",
    path: "/en/embed/:type()/:slug()",
    meta: _91slug_93HAzPQI1b4NMeta || {},
    component: () => import("/usr/src/app/pages/embed/[type]/[slug].vue")
  },
  {
    name: "embed-type-slug___id",
    path: "/embed/:type()/:slug()",
    meta: _91slug_93HAzPQI1b4NMeta || {},
    component: () => import("/usr/src/app/pages/embed/[type]/[slug].vue")
  },
  {
    name: "index___en",
    path: "/en",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "index___id",
    path: "/",
    meta: indexgw2ikJsyhcMeta || {},
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "login___en",
    path: "/en/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "login___id",
    path: "/login",
    meta: loginRGcvZC480vMeta || {},
    component: () => import("/usr/src/app/pages/login.vue")
  },
  {
    name: "policy-slug___en",
    path: "/en/policy/:slug()",
    component: () => import("/usr/src/app/pages/policy/[slug].vue")
  },
  {
    name: "policy-slug___id",
    path: "/policy/:slug()",
    component: () => import("/usr/src/app/pages/policy/[slug].vue")
  },
  {
    name: "product-slug___en",
    path: "/en/product/:slug()",
    meta: _91slug_93rJ4KGHwFnjMeta || {},
    component: () => import("/usr/src/app/pages/product/[slug].vue")
  },
  {
    name: "product-slug___id",
    path: "/product/:slug()",
    meta: _91slug_93rJ4KGHwFnjMeta || {},
    component: () => import("/usr/src/app/pages/product/[slug].vue")
  },
  {
    name: "promo-gopartner___en",
    path: "/en/promo/gopartner",
    component: () => import("/usr/src/app/pages/promo/gopartner.vue")
  },
  {
    name: "promo-gopartner___id",
    path: "/promo/gopartner",
    component: () => import("/usr/src/app/pages/promo/gopartner.vue")
  },
  {
    name: "promo-gopay-later___en",
    path: "/en/promo/gopay-later",
    component: () => import("/usr/src/app/pages/promo/gopay-later.vue")
  },
  {
    name: "promo-gopay-later___id",
    path: "/promo/gopay-later",
    component: () => import("/usr/src/app/pages/promo/gopay-later.vue")
  },
  {
    name: "promo-grab-benefits___en",
    path: "/en/promo/grab-benefits",
    component: () => import("/usr/src/app/pages/promo/grab-benefits.vue")
  },
  {
    name: "promo-grab-benefits___id",
    path: "/promo/grab-benefits",
    component: () => import("/usr/src/app/pages/promo/grab-benefits.vue")
  },
  {
    name: "promo-kredivo___en",
    path: "/en/promo/kredivo",
    component: () => import("/usr/src/app/pages/promo/kredivo.vue")
  },
  {
    name: "promo-kredivo___id",
    path: "/promo/kredivo",
    component: () => import("/usr/src/app/pages/promo/kredivo.vue")
  },
  {
    name: "register___en",
    path: "/en/register",
    meta: register6aSL4CEIZgMeta || {},
    component: () => import("/usr/src/app/pages/register.vue")
  },
  {
    name: "register___id",
    path: "/register",
    meta: register6aSL4CEIZgMeta || {},
    component: () => import("/usr/src/app/pages/register.vue")
  },
  {
    name: "resale-buyback-confirm___en",
    path: "/en/resale/buyback/confirm",
    component: () => import("/usr/src/app/pages/resale/buyback/confirm.vue")
  },
  {
    name: "resale-buyback-confirm___id",
    path: "/resale/buyback/confirm",
    component: () => import("/usr/src/app/pages/resale/buyback/confirm.vue")
  },
  {
    name: "resale-buyback___en",
    path: "/en/resale/buyback",
    component: () => import("/usr/src/app/pages/resale/buyback/index.vue")
  },
  {
    name: "resale-buyback___id",
    path: "/resale/buyback",
    component: () => import("/usr/src/app/pages/resale/buyback/index.vue")
  },
  {
    name: "resale-thank-you___en",
    path: "/en/resale/thank-you",
    component: () => import("/usr/src/app/pages/resale/thank-you.vue")
  },
  {
    name: "resale-thank-you___id",
    path: "/resale/thank-you",
    component: () => import("/usr/src/app/pages/resale/thank-you.vue")
  },
  {
    name: "resale-trade-in-confirm___en",
    path: "/en/resale/trade-in/confirm",
    component: () => import("/usr/src/app/pages/resale/trade-in/confirm.vue")
  },
  {
    name: "resale-trade-in-confirm___id",
    path: "/resale/trade-in/confirm",
    component: () => import("/usr/src/app/pages/resale/trade-in/confirm.vue")
  },
  {
    name: "resale-trade-in___en",
    path: "/en/resale/trade-in",
    component: () => import("/usr/src/app/pages/resale/trade-in/index.vue")
  },
  {
    name: "resale-trade-in___id",
    path: "/resale/trade-in",
    component: () => import("/usr/src/app/pages/resale/trade-in/index.vue")
  },
  {
    name: "warranty-card-orderNumber___en",
    path: "/en/warranty-card/:orderNumber()",
    meta: _91orderNumber_93sJrKzifOf5Meta || {},
    component: () => import("/usr/src/app/pages/warranty-card/[orderNumber].vue")
  },
  {
    name: "warranty-card-orderNumber___id",
    path: "/warranty-card/:orderNumber()",
    meta: _91orderNumber_93sJrKzifOf5Meta || {},
    component: () => import("/usr/src/app/pages/warranty-card/[orderNumber].vue")
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/gopartner",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/en/gopartner",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/grab-benefits",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/en/grab-benefits",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/buyback",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/en/buyback",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/trade-in",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/en/trade-in",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/sitemap.xml",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/pages-sitemap.xml",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/collections-sitemap.xml",
    component: component_45stubuYxk0V0rW5
  },
  {
    name: component_45stubuYxk0V0rW5Meta?.name,
    path: "/products-sitemap.xml",
    component: component_45stubuYxk0V0rW5
  }
]